import { ComponentRef, EditorType, EventType } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { openElementsPanel } from '../panel/openElementsPanel';
import { updatePagesDataOnRouter } from '../utils/pagesUtils';
import _ from 'lodash';
import {
  classicDefaultInstallationSize,
  FeaturedCollectionWidgetData,
} from './first-install/const/pagesConsts';
import { AppDefId } from '../constants';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import {
  openDashboardProjects,
  openDashboardNewProject,
  openDashboardCollections,
} from '../utils/dashboardUtils';

export const editorAppEventListener = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  editorType: EditorType,
) => {
  const { t } = flowAPI.translations;
  const panelsApi = await new PanelsApiFactory().createPanelsApi(
    editorSDK,
    editorType,
  );
  await editorSDK.addEventListener('appActionClicked', async (event) => {
    const { actionId } = event.detail;
    switch (actionId) {
      case 'addNewProject':
        openDashboardNewProject(editorSDK)();
        break;
      case 'manageProjects':
        openDashboardProjects(editorSDK)();
        break;
      case 'manageCollections':
        openDashboardCollections(editorSDK)();
        break;
      case 'managePages':
        editorSDK.editor.deeplink.show('', {
          type: 'pagesPanel',
          params: [AppDefId],
        });
        break;
      case 'addWidgetToPage':
        const { widgetId, presetId, mobilePresetId } =
          FeaturedCollectionWidgetData;
        const containerRef = (await editorSDK.pages.getCurrent(
          '',
        )) as ComponentRef;
        editorSDK.application.appStudioWidgets.addWidget('', {
          widgetId,
          layout: {
            x: 0,
            y: 0,
            fixedPosition: false,
            width: classicDefaultInstallationSize.width,
            height: classicDefaultInstallationSize.height,
            scale: 1,
            rotationInDegrees: 0,
          } as any,
          scopedPresets: {
            desktop: { layout: presetId, style: presetId },
            mobile: { layout: mobilePresetId, style: mobilePresetId },
          },
          installationType: 'closed',
          containerRef,
        });
        break;
    }
  });
  // event for delete app page -- for dev work only
  await editorSDK.addEventListener(
    'deletePortfolioPage' as EventType,
    (event) => {
      const { pageRef } = event.detail;
      editorSDK.pages.remove('', {
        pageRef,
        shouldShowEditorRemovePanel: false,
      });
    },
  );
  // event for delete app -- for dev work only
  await editorSDK.addEventListener(
    'deletePortfolioApp' as EventType,
    async (event) => {
      await editorSDK.application.uninstall('', { openConfirmation: true });
    },
  );

  const getVariationId = (preset: string) => {
    // remove externalPreset-
    return preset.slice(preset.indexOf('-') + 1);
  };

  await editorSDK.addEventListener(
    'globalDesignPresetChanged',
    async (event) => {
      const { preset, componentRef } = event.detail;

      if (preset.startsWith('externalPreset-')) {
        const variationId = getVariationId(preset);
        const [parentRefComponent] = await editorSDK.components.getAncestors(
          '',
          {
            componentRef,
          },
        );

        // reset design changes before changing template
        if (variationId && parentRefComponent) {
          await editorSDK.components.refComponents.removeAllOverrides('', {
            componentRef: parentRefComponent,
          });
          await editorSDK.application.appStudioWidgets.changePreset('', {
            componentRef: parentRefComponent,
            stylePresetId: variationId,
            layoutPresetId: variationId,
          });
          editorSDK.document.application.livePreview.refresh('', {
            shouldFetchData: true,
            source: '',
          });
        }
      }
    },
  );

  await editorSDK.addEventListener('widgetGfppClicked', async (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case 'openElementsPanel':
        void openElementsPanel(editorSDK, componentRef, t);
        break;
      case 'openFeaturedCollectionPanel':
        await panelsApi.openBlocksPanel('selectedCollection', componentRef);
        break;
    }
  });
  await editorSDK.addEventListener('pageRenamed', (event) => {
    updatePagesDataOnRouter(editorSDK);
  });
};
