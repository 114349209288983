import header1image from '../../../assets/media/project-header-presets/header-1.png';
import header2image from '../../../assets/media/project-header-presets/header-2.png';
import header3image from '../../../assets/media/project-header-presets/header-3.png';
import header4image from '../../../assets/media/project-header-presets/header-4.png';
import header5image from '../../../assets/media/project-header-presets/header-5.png';
import header6image from '../../../assets/media/project-header-presets/header-6.png';
import header7image from '../../../assets/media/project-header-presets/header-7.png';

export enum ProjectHeaderWidgetComponentIds {
  Root = '#projectHeaderWidgetRoot',
  Title = '#projectTitle',
  Description = '#projectDescription',
  HeroImage = '#projectHeroImage',
  Details = '#projectDetailsWidget',
}

export enum ProjectHeaderWidgetEvents {}
export const ProjectHeaderWidgetPresets = [
  {
    id: 'variants-kzwjjh6z1',
    src: header1image,
    layout: {
      width: '250px',
      height: '177px',
    },
  },
  {
    id: 'variants-l0ozyn1y',
    src: header2image,
    layout: {
      width: '250px',
      height: '279px',
    },
  },
  {
    id: 'variants-l0wjlplm',
    src: header3image,
    layout: {
      width: '250px',
      height: '283px',
    },
  },
  {
    id: 'variants-l0p0beoe',
    src: header4image,
    layout: {
      width: '250px',
      height: '206px',
    },
  },
  {
    id: 'variants-l1o0dkvb',
    src: header5image,
    layout: {
      width: '250px',
      height: '160px',
    },
  },
  {
    id: 'variants-l0p6ekc3',
    src: header6image,
    layout: {
      width: '250px',
      height: '200px',
    },
  },
  {
    id: 'variants-l1sgvall',
    src: header7image,
    layout: {
      width: '250px',
      height: '213px',
    },
  },
];
