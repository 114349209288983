import { AppManifest } from '@wix/platform-editor-sdk';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { configurePages, configPagesTabs } from './manifest/configurePages';
import { configureManagementActions } from './manifest/configureManagementActions';
import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import { setCustomManifest } from './manifest/customizeManifest';
import { getCollectionsLength } from '../services/dataServices';

export const modifyAppManifest = async (
  options,
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const { t } = flowAPI.translations;
  const { httpClient } = flowAPI;
  const enableAddPortfolioWidgetButton = flowAPI.experiments.enabled(
    'specs.portfolio.enableAddPortfolioWidgetButton',
  );
  const baseManifest = await fetchWidgetsStageData(options);

  const pages = (pagesBuilder) => configurePages(pagesBuilder, t);
  const pagesTab = (pagesTabBuilder) => configPagesTabs(pagesTabBuilder, t);
  const managementActions = (managementActionsBuilder) =>
    configureManagementActions(
      managementActionsBuilder,
      t,
      enableAddPortfolioWidgetButton,
    );

  const collectionsLength = await getCollectionsLength(httpClient);
  appManifestBuilder
    .withJsonManifest(baseManifest)
    .configurePages(pages)
    .configurePagesTab(pagesTab)
    .configureManagementActions(managementActions);
  const appManifest = appManifestBuilder.build();
  setCustomManifest(appManifest, t, collectionsLength);
  return appManifest;
};
