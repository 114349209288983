import project1image from '../../../assets/media/project-page-presets/project-page-1.png';
import project2image from '../../../assets/media/project-page-presets/project-page-2.png';
import project3image from '../../../assets/media/project-page-presets/project-page-3.png';
import project4image from '../../../assets/media/project-page-presets/project-page-4.png';
import project5image from '../../../assets/media/project-page-presets/project-page-5.png';
import project6image from '../../../assets/media/project-page-presets/project-page-6.png';
import project7image from '../../../assets/media/project-page-presets/project-page-7.png';
import { CollectionWidgetComponentIds } from '../../collectionWidget/config/constants';
import { NavigationWidgetComponentIds } from '../../navigationWidget/config/constants';


export enum CollectionPageWidgetComponentIds {
  Root = '#collectionPageWidgetRoot',
  CollectionWidget = '#collectionWidget',
  NavigationWidget = '#navigationWidget',
}
export enum CollectionPageWidgetEvents {}

// temp use of project presets images till Liron add new ones
export const CollectionPageWidgetPresets = [
  {
    id: 'externalPreset-variants-kzwjby151',
    src: project1image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3h5jn83',
    src: project2image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3h5l0hl',
    src: project3image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3h5o9oq',
    src: project4image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3hcdw0i',
    src: project5image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3hce8x2',
    src: project6image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3hcet52',
    src: project7image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3hcf8zk',
    src: project1image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    id: 'externalPreset-variants-l3hcfkii',
    src: project2image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
];

export const CollectionPageWidgetTabsArray = [
  {
    label: 'Collection_Design_Panel_Title',
    roles: [CollectionWidgetComponentIds.Title],
  },
  {
    label: 'Collection_Design_Panel_Description',
    roles: [CollectionWidgetComponentIds.Description],
  },
  {
    label: 'Collection_Design_Panel_Projects_Gallery',
    roles: [CollectionWidgetComponentIds.Gallery],
  },
  {
    label: 'Collection_Design_Panel_Back_Button',
    roles: [NavigationWidgetComponentIds.BackButtonWidget],
  },
];
