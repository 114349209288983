import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

const refreshAppServices = (editorSDK: FlowEditorSDK) => {
  return async () => {
    editorSDK.document.application.livePreview.refresh('', {
      shouldFetchData: true,
      source: '',
    });
    editorSDK.document.application.reloadManifest();
    const isDynamicPage = !!(await editorSDK.routers.getCurrentDynamicRouting(
      '',
    ));
    isDynamicPage && editorSDK.editor.routers.refresh('');
  };
};

const openDashboardPanel = (editorSDK: FlowEditorSDK, url) => {
  editorSDK.editor
    .openDashboardPanel('', {
      url,
      closeOtherPanels: false,
    })
    .then(refreshAppServices(editorSDK));
};

export const openDashboardProjects = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(editorSDK, '/portfolio/projects');
};

export const openDashboardNewProject = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(editorSDK, '/portfolio/project');
};

export const openDashboardCollections = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(
    editorSDK,
    '/portfolio/collections?navToCollectionIfNeeded=true',
  );
};

export const openDashboardNewCollection = (editorSDK: FlowEditorSDK) => () => {
  openDashboardPanel(editorSDK, '/portfolio/collection');
};
