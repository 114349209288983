import { WidgetBuilder } from '@wix/yoshi-flow-editor';

export const getRole = (wixCodeId: string) => {
  return wixCodeId.substring(1);
};

const addTabExec = (widgetDesignTabsBuilder, label: string, roles: any) => {
  widgetDesignTabsBuilder.addTab((tabBuilder) => {
    tabBuilder
      .set({
        label,
      })
      .groups()
      .set({
        roles: roles.map(getRole),
      });
  });
};

export const setWidgetDesignTabs = (
  widgetDesignTabsBuilder,
  widgetDesignTabsArray,
  t,
) => {
  widgetDesignTabsArray.forEach(({ label, roles }) => {
    addTabExec(widgetDesignTabsBuilder, t(label), roles);
  });
};

export const hideNativeGalleryGFPPs = (
  controllerBuilder: WidgetBuilder,
  shouldRemoveSettings?: boolean,
) => {
  controllerBuilder.configureConnectedComponents(
    'collectionsGallery',
    (componentBuilder) => {
      componentBuilder.gfpp().set('connect', { behavior: 'HIDE' });
      if (shouldRemoveSettings) {
        componentBuilder.gfpp().set('settings', { behavior: 'HIDE' });
        componentBuilder.gfpp('mobile').set('settings', { behavior: 'HIDE' });
      }
    },
  );
};
