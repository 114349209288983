import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { WidgetBuilder, FlowEditorSDK, FlowAPI } from '@wix/yoshi-flow-editor';
import { hideNativeGalleryGFPPs } from '../../utils/manifestUtils';


export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder: WidgetBuilder,
  editorSDK: FlowEditorSDK,
  flowAPI: FlowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Project_Widget'),
    nickname: 'projectWidget',
  });
  hideNativeGalleryGFPPs(controllerBuilder);
};
