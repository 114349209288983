import { IHttpClient, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  createNewPortfolioApp,
  queryProjectsWithCollectionInfo,
  queryProjects,
  getProjectPageData,
} from '@wix/ambassador-portfolio-projects-v1-project/build/es/http';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import { SortOrder } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import {
  getCollectionSlugFromUrl,
  getProjectSlugFromUrl,
} from '../utils/urlsUtils';

export const getPortfolioApi = (httpClient: IHttpClient) => {
  const notifyPortfolioAppCreation = async () => {
    try {
      const data = await httpClient.request(createNewPortfolioApp({}));
      return data;
    } catch (e) {}
  };
  return { notifyPortfolioAppCreation };
};

export const getCollections = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: false },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections;
    });
};
export const getCollectionProjects = async (
  httpClient: IHttpClient,
  collectionId: string,
) => {
  return httpClient
    .request(
      queryProjectsWithCollectionInfo({
        query: {
          filter: {
            collectionId: { $eq: collectionId },
            hidden: { $eq: false },
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { projects = [] },
      } = res;
      return projects;
    });
};

export const getFirstCollection = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: { $eq: false } },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections[0];
    });
};

export const getCollectionBySlug = async (
  flowAPI: PlatformControllerFlowAPI,
) => {
  const collectionSlug = getCollectionSlugFromUrl(flowAPI);
  return flowAPI.httpClient
    .request(queryCollections({ query: { filter: { slug: collectionSlug } } }))
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;

      return collections[0];
    });
};

export const getProjects = async (
  httpClient: IHttpClient,
  collectionId: string,
) => {
  return httpClient
    .request(
      queryProjectsWithCollectionInfo({
        query: {
          filter: {
            collectionId: { $eq: collectionId },
            hidden: { $eq: false },
          },
          sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
        },
      }),
    )
    .then((res) => {
      const {
        data: { projects = [] },
      } = res;
      return projects;
    });
};

export const getFirstProject = async (httpClient: IHttpClient) => {
  return httpClient.request(queryProjects({ query: {} })).then((res) => {
    const {
      data: { projects = [] },
    } = res;
    return { project: projects[0] };
  });
};

export const getProjectBySlug = async (flowAPI: PlatformControllerFlowAPI) => {
  const projectSlug = getProjectSlugFromUrl(flowAPI);
  const collectionSlug = getCollectionSlugFromUrl(flowAPI);
  const res = await flowAPI.httpClient.request(
    getProjectPageData({ collectionSlug, projectSlug }),
  );

  return res.data;
};

export const getCollectionsLength = async (httpClient: IHttpClient) => {
  return httpClient
    .request(
      queryCollections({
        query: {
          filter: { hidden: false },
        },
      }),
    )
    .then((res) => {
      const {
        data: { collections = [] },
      } = res;
      return collections?.length;
    })
    .catch((e) => {
      console.error(e);
      return 0;
    });
};
