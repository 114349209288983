import { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { getRole } from '../../utils/manifestUtils';
import { ProjectHeaderWidgetComponentIds } from './config/constants';

const setImageBoxGFPPs = (controllerBuilder) => {
  controllerBuilder.configureConnectedComponents(
    'imageBox',
    (componentBuilder) => {
      componentBuilder
        .gfpp()
        .set('design', {
          target: {
            role: [ProjectHeaderWidgetComponentIds.HeroImage].map(getRole),
          },
        })
        .set('animation', {
          target: {
            role: [ProjectHeaderWidgetComponentIds.HeroImage].map(getRole),
          },
        });
    },
  );
};

export const getWidgetManifest: GetWidgetManifestFn = (
  controllerBuilder,
  editorSDK,
  flowAPI,
) => {
  const { t } = flowAPI.translations;
  controllerBuilder.set({
    displayName: t('Widget_Name_Project_Header_Widget'),
    nickname: 'projectHeaderWidget',
  });
  setImageBoxGFPPs(controllerBuilder);
};
