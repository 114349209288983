import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import _ from 'lodash';
import { PortfolioRoutersPrefix } from '../editor-app/first-install/const/pagesConsts';
import { initPortfolioPrefix } from '../editor-app/first-install/utils/installationUtils';

const PORTFOLIO_APP_DEF_ID = 'd90652a2-f5a1-4c7c-84c4-d4cdcc41f130';

export async function setPortfolioPagesStates(editorSDK: FlowEditorSDK) {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  const portfolioPages = portfolioPagesData.filter(
    ({ managingAppDefId }) => managingAppDefId === PORTFOLIO_APP_DEF_ID,
  );
  portfolioPages.forEach((pageData) => {
    editorSDK.document.pages.setState('', {
      state: {
        // @ts-expect-error
        [pageData.tpaPageId]: [{ id: pageData.id }],
      },
    });
  });
}

export const getApplicationRouter = async (
  editorSDK: FlowEditorSDK,
  prefix: string,
) => {
  const siteRouters = await editorSDK.routers.getAll('');
  const portfolioRouters = _.find(
    siteRouters,
    (router) => router.prefix === prefix,
  );
  return portfolioRouters;
};

export const updatePagesDataOnRouter = async (editorSDK: FlowEditorSDK) => {
  const portfolioPagesData = await editorSDK.pages.getApplicationPages('');
  const portfolioRouters = await getApplicationRouter(
    editorSDK,
    PortfolioRoutersPrefix.PORTFOLIO_COLLECTIONS,
  );
  if (portfolioRouters) {
    const routerRef = await editorSDK.routers.getByPrefix('', {
      prefix: portfolioRouters?.prefix as string,
    });
    await editorSDK.routers.update('', {
      routerRef,
      config: { portfolioPagesData },
    });
  }
};

export const isProjectRouterAdded = async (editorSDK: FlowEditorSDK) => {
  const portfolioRouters = await getApplicationRouter(
    editorSDK,
    PortfolioRoutersPrefix.PORTFOLIO_PROJECT,
  );
  return !!portfolioRouters;
};

export const addPortfolioProjectsIfNeeded = async (
  editorSDK: FlowEditorSDK,
) => {
  const isProjectRouterAddedToPage = await isProjectRouterAdded(editorSDK);
  if (!isProjectRouterAddedToPage) {
    const portfolioProjectRouterRef = await initPortfolioPrefix(
      editorSDK,
      PortfolioRoutersPrefix.PORTFOLIO_PROJECT,
    );
  }
};
