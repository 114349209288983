import _ from 'lodash';
import { getPortfolioPageStateSettings } from './configurePages';
import PortfolioComponentJson from '../../components/portfolioPageWidget/.component.json';
import { CollectionsGalleryWidgetComponentIds } from '../../components/collectionsGalleryWidget/config/constants';
import { getRole } from '../../utils/manifestUtils';

const hidePortfolioPageWidgetElementsGFPP = (appManifest, t) => {
  appManifest.controllersStageData[
    PortfolioComponentJson.controllerId
  ].default.gfpp.desktop.iconButtons.add = { behavior: 'HIDE' };
};
const setPortfolioPageWidgetDesignTab = (appManifest, t) => {
  appManifest.controllersStageData[
    PortfolioComponentJson.controllerId
  ].default.gfpp.desktop.widgetDesign.tabs = [
    {
      label: t('Portfolio_Design_Panel_Projects_Gallery'),
      groups: {
        roles: [CollectionsGalleryWidgetComponentIds.GalleyWidget].map(getRole),
      },
    },
  ];
};
export const setCustomManifest = (
  appManifest: any,
  t: any,
  collectionsLength: number,
) => {
  appManifest.pages.pageSettings.Portfolio = getPortfolioPageStateSettings(t);
  if (collectionsLength > 1) {
    hidePortfolioPageWidgetElementsGFPP(appManifest, t);
    setPortfolioPageWidgetDesignTab(appManifest, t);
  }
};
